






























































































































import router from "@/router";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref
} from "@vue/composition-api";

import VuePhoneNumberInput from "vue-phone-number-input";
import { getPhoneInputOptions } from "@/helpers/phone-input";
import store from "@/store";

export default defineComponent({
  name: "Login",
  components: {
    VuePhoneNumberInput
  },
  setup(_props, context) {
    const phoneInput = ref(null);
    const state = reactive({
      isLoading: false,
      loginInfo: {
        phone: "",
        password: ""
      },
      isPhoneNumberValid: false,
      rememberMe: true,
      canLogin: computed((): boolean => {
        return state.isPhoneNumberValid && state.loginInfo.password.length >= 6;
      })
    });

    const phoneInputOptions = getPhoneInputOptions();

    async function login() {
      state.isLoading = true;
      const loginResult = await store.dispatch.users.login({
        telephone: (phoneInput.value as any).phoneFormatted,
        password: state.loginInfo.password
      });
      if (!loginResult.success) {
        state.isLoading = false;
        context.root.$notify({
          group: "notifs",
          type: "error",
          title: "Oupss",
          text: loginResult.message
        });
      } else {
        await store.dispatch.users.getCurrentUserInfo();
        const waitingRoute = store.state.common.routeWaitingForLoggedUser;
        if (waitingRoute) {
          store.commit.common.RESET_WAITING_FOR_LOGIN_ROUTE();
          router.push({ name: waitingRoute });
        } else {
          router.replace("/mon-profil");
        }
      }
    }

    function onPhoneInputUpdate(eventParams: any) {
      state.isPhoneNumberValid = eventParams.isValid;
    }

    return {
      login,
      state,
      phoneInputOptions,
      onPhoneInputUpdate,
      phoneInput
    };
  }
});
